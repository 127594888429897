@import '../../styles/typography.scss';
@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.about {
    @include about-padding();
    background-color: $green;
    display: flex;
    align-items: center;
    gap: 30px;

    @media (max-width: 1000px) {
        flex-wrap: wrap;
    }

    &-image {
        height: 391px;
        width: auto;
        border-radius: $outer-border-radius;

        @media (max-width: 500px) {
            width: 100%;
            height: auto;
        }
    }

    &-text-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        color: $white;
    }

    &-label {
        @include comfortaa-16px();
        color: rgba(255, 255, 255, .6);
    }

    &-title {
        @include comfortaa-36px();
        color: $white;
        font-weight: bold;
    }

    &-text {
        @include inter-16px();
        color: rgba(255, 255, 255, .8);
    }
}