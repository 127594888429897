@import '../../styles/typography.scss';
@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.nav {
    @include navbar-padding();
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    align-items: center;
    justify-content: space-between;
    background-color: $white-transparent;
    backdrop-filter: blur(3px);

    &-title {
        @include comfortaa-27px();
        font-weight: bold;
    }

    &-links-container {
        display: flex;
        align-items: center;
        gap: 20px;
    }

    &-link {
        @include inter-16px();

        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }

}