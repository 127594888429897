@import '../../styles/typography.scss';
@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.faq {
    @include content-padding();
    display: flex;
    flex-direction: column;
    gap: 20px;

    &-label {
        @include comfortaa-16px();
        color: rgba(0, 0, 0, .5);
    }

    &-title {
        @include comfortaa-36px();
        color: $black;
        font-weight: bold;
    }

    &-card {
        @include card-style();
        @include inter-16px();
        display: flex;
        flex-direction: column;
        gap: 10px;

        &-question {
            font-weight: bold;
            color: $black;
        }

        &-answer {
            color: $dark-gray;
        }
    }
}