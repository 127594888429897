@import '../../styles/typography.scss';
@import '../../styles/variables.scss';

.contact-modal {
    @include card-style();
    z-index: 10000000;

    height: fit-content;
    max-height: 90vh;
    margin: auto;
    width: fit-content;
    max-width: 70vw;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (max-width: 700px) {
        max-width: 95vw;
        width: 95vw;
    }

    &-overlay {
        z-index: 1000;
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(0, 0, 0, .6);
        backdrop-filter: blur(2px);
    }

    &-header-row {
        display: flex;
        justify-content: space-between;
    }

    &-title {
        @include comfortaa-27px();
        color: $black;
        font-weight: bold;
    }

    &-social-links {
        display: flex;
        gap: 15px;
        align-items: center;
    }

    &-social-link {
        color: $green;
        transition: transform ease-in-out 300ms;
        height: auto;
        width: 24px;

        &:hover {
            cursor: pointer;
            transform: translateY(-3px);
        }
    }

    &-input-form {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: end;
        width: 100%;
        min-width: 400px;

        @media (max-width: 700px) {
            min-width: 100%;
        }

        &>input,
        &>textarea {
            @include inter-16px();
            width: 100%;
            padding: 15px;
            border-radius: $outer-border-radius;
            border: 1px solid $black;
            resize: vertical;
        }

        &>a {
            background-color: $green;
            padding: 10px 20px;
            transition: transform ease-in-out 300ms;
            border-radius: $outer-border-radius;
            color: $white;
            font-weight: 600;
            text-decoration: none;

            &:hover {
                cursor: pointer;
                text-decoration: underline;
                transform: translateY(-2px);
            }
        }
    }

}