@mixin content-padding {
    padding: 0px 256px;

    @media (max-width: 1300px) {
        padding: 0px 175px;
    }

    @media (max-width: 1100px) {
        padding: 0px 75px;
    }

    @media (max-width: 900px) {
        padding: 0px 50px;
    }

    @media (max-width: 800px) {
        padding: 0px 20px;
    }
}

@mixin about-padding {
    padding: 100px 256px;

    @media (max-width: 1300px) {
        padding: 100px 175px;
    }

    @media (max-width: 1100px) {
        padding: 100px 75px;
    }

    @media (max-width: 900px) {
        padding: 100px 50px;
    }

    @media (max-width: 800px) {
        padding: 100px 20px;
    }
}

@mixin navbar-padding {
    padding: 70px 150px 30px 150px;

    @media (max-width: 1300px) {
        padding: 70px 120px 30px 120px;
    }

    @media (max-width: 1100px) {
        padding: 50px 60px 30px 60px;
    }

    @media (max-width: 900px) {
        padding: 30px 50px 30px 50px;
    }

    @media (max-width: 800px) {
        padding: 30px 20px 30px 20px;
    }
}

@mixin footer-padding {
    padding: 30px 150px 70px 150px;

    @media (max-width: 1300px) {
        padding: 30px 120px 70px 120px;
    }

    @media (max-width: 1100px) {
        padding: 30px 60px 50px 60px;
    }

    @media (max-width: 900px) {
        padding: 30px 50px 30px 50px;
    }

    @media (max-width: 800px) {
        padding: 30px 20px 30px 20px;
    }
}