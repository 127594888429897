@import '../../styles/typography.scss';
@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.events {
    @include content-padding();
    display: flex;
    flex-direction: column;
    gap: 20px;

    &-label {
        @include comfortaa-16px();
        color: rgba(0, 0, 0, .5);
    }

    &-section {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    &-title {
        @include comfortaa-36px();
        color: $black;
        font-weight: bold;
    }

    &-text {
        @include inter-16px();
        color: $dark-gray;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

}