@import '../../styles/typography.scss';
@import '../../styles/variables.scss';

.button {
    background-color: $green;
    padding: 10px 20px;
    transition: transform ease-in-out 300ms;
    border-radius: $outer-border-radius;
    color: $white;
    text-align: center;
    font-weight: 600;

    &:not(.unclickable):hover {
        cursor: pointer;
        text-decoration: underline;
        transform: translateY(-2px);
    }
}