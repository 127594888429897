@import './typography.scss';
@import './variables.scss';

#app-container {
    display: flex;
    flex-direction: column;
    gap: 120px;
}


@keyframes ellipsis {
    to {
        width: 25px;
    }
}

@-webkit-keyframes ellipsis {
    to {
        width: 25px;
    }
}

.loading {
    @include comfortaa-27px();
    color: $black;
    font-weight: bold;

    &-error-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 100vh;
        gap: 10px;

        @include comfortaa-21px();
        color: $black;
        font-weight: bold;
    }

    &:after {
        overflow: hidden;
        display: inline-block;
        vertical-align: bottom;
        -webkit-animation: ellipsis steps(4, end) 900ms infinite;
        animation: ellipsis steps(4, end) 900ms infinite;
        content: "\2026";
        /* ascii code for the ellipsis character */
        width: 0px;
    }

}