@import '../../styles/typography.scss';
@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.issues {
    @include content-padding();
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    &-text {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 10px;
    }

    &-title {
        @include comfortaa-36px();
        color: $black;
        font-weight: bold
    }

    &-subtitle {
        @include inter-16px();
        color: $dark-gray;
    }

    &-list {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-wrap: wrap;
    }

}