@import '../../styles/typography.scss';
@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.footer {
    @include footer-padding();
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-social-links {
        display: flex;
        gap: 15px;
        align-items: center;
    }

    &-social-link {
        color: $green;
        transition: transform ease-in-out 300ms;
        height: auto;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            cursor: pointer;
            transform: translateY(-3px);
        }
    }

    &-ndd-note {
        @include inter-12px();
        color: $dark-gray;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        &>a {
            color: $green;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }



}