@font-face {
    font-family: 'Comfortaa';
    src: url('../assets/Comfortaa-VariableFont_wght.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@mixin comfortaa {
    font-family: 'Comfortaa';
}

@mixin comfortaa-61px {
    @include comfortaa();
    font-size: 61px;
}

@mixin comfortaa-47px {
    @include comfortaa();
    font-size: 47px;
}

@mixin comfortaa-36px {
    @include comfortaa();
    font-size: 36px;
}

@mixin comfortaa-27px {
    @include comfortaa();
    font-size: 27px;
}

@mixin comfortaa-21px {
    @include comfortaa();
    font-size: 21px;
}

@mixin comfortaa-16px {
    @include comfortaa();
    font-size: 16px;
}

@mixin inter {
    font-family: 'Inter', Arial, Helvetica, sans-serif
}

@mixin inter-27px {
    @include inter();
    font-size: 27px;
}

@mixin inter-21px {
    @include inter();
    font-size: 21px;
}

@mixin inter-16px {
    @include inter();
    font-size: 16px;
    line-height: 22px;
}

@mixin inter-12px {
    @include inter();
    font-size: 12px;
    line-height: 16px;
}