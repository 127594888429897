@import '../../styles/typography.scss';
@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.site-header {
    @include content-padding();
    margin-top: 249px;
    display: flex;
    align-items: center;
    gap: 30px;

    @media (max-width: 1200px) {
        margin-top: 229px;
        flex-wrap: wrap-reverse;
    }

    @media (max-width: 900px) {
        margin-top: 155px;
    }

    &-text-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        flex-grow: 1;
        max-width: 37%;

        @media (max-width: 1200px) {
            max-width: unset;
        }

        @media (max-width: 1200px) {
            text-align: center;
        }
    }

    &-title {
        @include comfortaa-61px();
        font-weight: bold;
        color: $black;

        @media (max-width: 1200px) {
            @include comfortaa-47px();
        }
    }

    &-subtitle {
        @include inter-27px();
        color: $dark-gray;

        @media (max-width: 1200px) {
            @include inter-21px();
        }
    }

    &-image {
        object-fit: cover;
        border-radius: $outer-border-radius;
        height: 100%;
        width: 100%;

        &-container {
            height: 100%;
            width: auto;
        }
    }
}