$green: #0CC6A4;
$white: #fafafa;
$white-transparent: #fafafaf4;
$dark-gray: #888888;
$black: #343434;

$outer-border-radius: 25px;
$inner-border-radius: 13px;

@mixin card-style {
    padding: 30px;
    background-color: white;
    border-radius: $outer-border-radius;
    border: 1px solid #eee;
}